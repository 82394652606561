import styles from "./Sidebar.module.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoUser from "../../assets/logo/profile-img.png";
import isLogged from "../../utils/useLogged";

import BannerVid from "../../assets/vid/banner.mp4";

const links = [
  {
    href: "/",
    title: "Home",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-layout-dashboard"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path d="M15 18H9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    ),
    text: "Home",
  },
  {
    href: "/leaderboard",
    title: "Leaderboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-transform-filled"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          d="M15 21H9V12.6C9 12.2686 9.26863 12 9.6 12H14.4C14.7314 12 15 12.2686 15 12.6V21Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4 21H15V18.1C15 17.7686 15.2686 17.5 15.6 17.5H20.4C20.7314 17.5 21 17.7686 21 18.1V20.4C21 20.7314 20.7314 21 20.4 21Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 21V16.1C9 15.7686 8.73137 15.5 8.4 15.5H3.6C3.26863 15.5 3 15.7686 3 16.1V20.4C3 20.7314 3.26863 21 3.6 21H9Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.8056 5.11325L11.7147 3.1856C11.8314 2.93813 12.1686 2.93813 12.2853 3.1856L13.1944 5.11325L15.2275 5.42427C15.4884 5.46418 15.5923 5.79977 15.4035 5.99229L13.9326 7.4917L14.2797 9.60999C14.3243 9.88202 14.0515 10.0895 13.8181 9.96099L12 8.96031L10.1819 9.96099C9.94851 10.0895 9.67568 9.88202 9.72026 9.60999L10.0674 7.4917L8.59651 5.99229C8.40766 5.79977 8.51163 5.46418 8.77248 5.42427L10.8056 5.11325Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    text: "Leaderboard",
  },
  {
    href: "/challenges",
    title: "Desafíos",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-chart-pie"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" />
        <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" />
      </svg>
    ),
    text: "Desafíos",
  },
  {
    href: "/bonus-hunt",
    title: "BonusHunt",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24">
        <path
          fill="#d9cbd5"
          d="M7 21v-2h4v-3.1q-1.225-.275-2.187-1.037T7.4 12.95q-1.875-.225-3.137-1.637T3 8V5h4V3h10v2h4v3q0 1.9-1.263 3.313T16.6 12.95q-.45 1.15-1.412 1.913T13 15.9V19h4v2zm0-10.2V7H5v1q0 .95.55 1.713T7 10.8m5 3.2q1.25 0 2.125-.875T15 11V5H9v6q0 1.25.875 2.125T12 14m5-3.2q.9-.325 1.45-1.088T19 8V7h-2zm-5-1.3"
        />
      </svg>
    ),
    text: "Bonus Hunt",
  },
  // {
  //   href: "https://roobet.com/?ref=frankkaster",
  //   title: "Codigo Frankkaster",
  //   icon: (
  //     <video className="video-main" autoPlay loop muted>
  //       <source src={BannerVid} type="video/mp4" />
  //     </video>
  //   ),
  //   text: "Codigo Frankkaster",
  // },
];

const LinkSettings = [
  {
    href: "/settings/profile/",
    title: "Ajustes",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24">
        <path
          fill="#d9cbd5"
          d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12q0-1.45-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12q0 1.45 1.013 2.475T12.05 15.5"
        />
      </svg>
    ),
    text: "Ajustes",
  },
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const [user, setUser] = useState([]);

  isLogged(user, setUser, useEffect);

  const handleLinkClick = (index, href) => {
    setActiveIndex(index);
    // Lógica para manejar el clic en el enlace
  };

  return (
    <nav className={`${styles.sidebar} ${collapsed ? styles.collapsed : ""}`}>
      <div className={styles.sidebarTopWrapper}>
        <button
          className={styles.expandBtn}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.00979 2.72L10.3565 7.06667C10.8698 7.58 10.8698 8.42 10.3565 8.93333L6.00979 13.28"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.sidebarLinks}>
        {/* <h2>Principal</h2> */}
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <Link
                to={link.href}
                title={link.title}
                className={styles.tooltip + ` ${activeIndex === index ? styles.active : ""}`}
                onClick={() => handleLinkClick(index, link.href)}
              >
                {link.icon}
                <span className={styles.link + " " + styles.hide}>{link.text}</span>
                <span className={styles.tooltipContent}>{link.text}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Link className={styles.posPubli} to="https://roobet.com/?ref=frankkaster" target="_blank" rel="noreferrer">
      <video className={styles.videoMain} autoPlay loop muted>
        <source src={BannerVid} type="video/mp4" />
      </video>
      </Link>
      {/* Repite lo mismo para otras secciones de la barra lateral */}
      <div className={styles.sidebarLinks + " " + styles.bottomLinks}>
        {/* <h2>Settings</h2> */}
        <ul>
          {/* Agrega enlaces de configuración aquí */}

          {LinkSettings.map((linkSettings, indexSettings) => (
            <li key={indexSettings}>
              {user.username ? (
                <>
                  {/* <h2>Configuración</h2> */}
                  <Link
                    to={linkSettings.href}
                    title={linkSettings.title}
                    className={styles.tooltip + ` ${activeIndex === indexSettings ? styles.active : ""}`}
                    onClick={() => handleLinkClick(indexSettings, linkSettings.href)}
                  >
                    {linkSettings.icon}
                    <span className={styles.link + " " + styles.hide}>{linkSettings.text}</span>
                    <span className={styles.tooltipContent}>{linkSettings.text}</span>
                  </Link>
                </>
              ) : (
                " "
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.sidebarProfile}>
        <div className={styles.avatarWrapper}>
          {user.username ? (
            <img className={styles.avatar} src={user.avatar} alt="" />
          ) : (
            <img className={styles.avatar} src={logoUser} alt="Profile Pic" />
          )}
          <div className={styles.onlineStatus}></div>
        </div>
        <section className={styles.avatarName + " " + styles.hide}>
          {user.username ? <div className={styles.userName}>{user.display_name}</div> : <div className={styles.userName}>Please Login</div>}
          {/* <div className={styles.email}>Points: 0</div> */}
        </section>
        {user.username ? (
          <a href="/api/auth/logout" className={styles.logout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={styles.icon + " " + styles.iconTabler + " " + styles.iconTablerLogout}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
              <path d="M9 12h12l-3 -3"></path>
              <path d="M18 15l3 -3"></path>
            </svg>
          </a>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};

export default Sidebar;
