import { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Sidebar from "./components/Sidebar/Sidebar.jsx";
import MenuResponsive from "./components/MenuResponsive2/MenuResponsive2.jsx";
import Confetti from "react-confetti";

const Header = lazy(() => import("../src/components/Header/Header.jsx"));
const StreamLive = lazy(() => import("../src/components/StreamLive/StreamLive.jsx"));
const Home = lazy(() => import("../src/pages/Home.jsx"));
const LoadingContent = lazy(() => import("../src/components/LoadingContent/LoadingContent.jsx"));
const Terms = lazy(() => import("./components/TermsAndConditions/Terms.jsx"));
const Privacy = lazy(() => import("./components/PrivacyPolicy/Privacy.jsx"));
const Gamble = lazy(() => import("./components/GambleAware/Gamble.jsx"));
const Telon = lazy(() => import("./components/Telon/Telon.jsx"));
const Loading = lazy(() => new Promise((resolve, reject) => setTimeout(() => resolve(import("../src/components/Loading/Loading.jsx")), 5000)));

const Error = lazy(() => import("../src/pages/404.jsx"));
const Challenges = lazy(() => import("./pages/Challenges.jsx"));
const ChallengesPreviousPage = lazy(() => import("./pages/ChallengesPreviousPage.jsx"));
const BonusHunt = lazy(() => import("./pages/BonusHunt.jsx"));
const Leaderboard = lazy(() => import("./pages/Leaderboard.jsx"));
const LeaderboardPreviousPage = lazy(() => import("./pages/LeaderboardPreviousPage.jsx"));
const Settings = lazy(() => import("./pages/Settings.jsx"));
const Footer = lazy(() => import("../src/components/Footer/Footer.jsx"));

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetch("/api/auth/user")
        .then((res) => res.json())
        .then((data) => {
          setUser(data);
          localStorage.setItem("user", JSON.stringify(data));
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }, 2500);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  // const [user, setUser] = useState([]);

  //   useEffect(() => {
  //       fetch('/api/auth/user', {
  //       })
  //       .then((res) => {
  //           return res.json();
  //       })
  //       .then((data) => {
  //           setUser(data);
  //       });
  //   }, []);

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <div className="wrapper">
          <Header />
          <main>
            <Sidebar />
            <div className="content">
              <Suspense fallback={<LoadingContent />}>
                <Routes path="/" element={<Header />}>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/challenges" element={<Challenges />} />
                  <Route exact path="/challenges-previous" element={<ChallengesPreviousPage />} />
                  <Route exact path="/bonus-hunt" element={<BonusHunt />} />
                  <Route exact path="/leaderboard" element={<Leaderboard />} />
                  <Route exact path="/leaderboard-previous" element={<LeaderboardPreviousPage />} />
                  <Route exact path="/settings/:settings" element={<Settings />} />
                  <Route exact path="/terms-and-conditions" element={<Terms />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  <Route exact path="/gamble-aware" element={<Gamble />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </Suspense>
              <Footer />
            </div>
          </main>
          <MenuResponsive />
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
