import { useEffect } from 'react';

export default function useLogged(user, setUser) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetch('/api/auth/user', {})
        .then(res => res.json())
        .then(data => {
          setUser(data);
          localStorage.setItem('user', JSON.stringify(data));
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }, 2500); // Retraso de 3 segundos

    // Limpiar el timeout si el componente se desmonta antes de que se complete
    return () => clearTimeout(timeoutId);
  }, [setUser]);
}
// export default function useLogged(user, setUser, useEffect) {
//   useEffect(() => {
//     fetch('/api/auth/user', {})
//       .then(res => {
//         return res.json();
//       })
//       .then(data => {
//         setUser(data);
//         localStorage.setItem('user', JSON.stringify(data));
//       });
//   }, []);
// }
